import { template as template_fd276bcdf68f410bb81e7ef1606640b4 } from "@ember/template-compiler";
const FKText = template_fd276bcdf68f410bb81e7ef1606640b4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
