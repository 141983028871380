import { template as template_66df19c381b14ba2bc2087df2d20f883 } from "@ember/template-compiler";
const SidebarSectionMessage = template_66df19c381b14ba2bc2087df2d20f883(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
