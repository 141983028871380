import { template as template_4c44a3e9a2484dac9d0cb6d0189964f1 } from "@ember/template-compiler";
const WelcomeHeader = template_4c44a3e9a2484dac9d0cb6d0189964f1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
