import { template as template_e1f943ce7919422a86d148b18cc9e5a2 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
export default class DeferredRender extends Component {
    @tracked
    shouldRender = false;
    constructor(){
        super(...arguments);
        runAfterFramePaint(()=>(this.shouldRender = true));
    }
    static{
        template_e1f943ce7919422a86d148b18cc9e5a2(`
    {{#if this.shouldRender}}
      {{yield}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
